import UserInfo from "@/models/UserInfo";
import { createContext, useContext } from "react";
import MembershipCard from "@/models/MembershipCard";
import Preorder from "@/models/Preorder";
import UserTickets from "@/models/UserTickets";
import Event from "@/models/Event"
import EventIssuer from "@/models/EventIssuer";
import ReductionCode from "@/models/ReductionCode";
import Section from "@/models/Section";
import MembershipCardCreationData from "@/models/MembershipCardCreationData";
import SellingTickets from "@/models/SellingTickets";
import UserTicket from "@/models/UserTicket";
import Ticketorder from "@/models/Ticketorder";
import PayoutProfile from "@/models/PayoutProfile";
import BalanceLog from "@/models/BalanceLog";
import { LoggedUser } from "@/models/AuthUser";
import MembershipCardValidation from "@/models/MembershipCardValidation";

interface ApiContextIFace {
    Login(email: string, password: string): Promise<boolean>;

    Register(email: string, password: string, name: string, privacyConsent: boolean, termsConsent: boolean, marketingConsent: boolean): Promise<UserInfo>

    Logout(fromAll: boolean): Promise<void>;

    IsLogged(): Promise<LoggedUser>;

    ConfirmUser(token: string): Promise<UserInfo>;

    ChangePassword(oldPassword: string, newPassword: string): Promise<UserInfo>;

    RecoveryPasswordRequest(email: string): Promise<UserInfo>;

    RecoveryPassword(token: string, newPassword: string): Promise<UserInfo>;

    UserInfo(): Promise<UserInfo>;

    GetMembershipCards(): Promise<MembershipCard[]>;

    DeleteMembershipCard(uuid: string): Promise<boolean>;

    GetMembershipCard(uuid: string): Promise<MembershipCard>;

    GetPreorder(): Promise<Preorder>;

    GetUserTickets(): Promise<UserTickets>;

    GetActiveEvents(): Promise<Event[]>;

    GetActiveEventIssuers(): Promise<EventIssuer[]>;

    GetEvent(uuid: string): Promise<Event>;

    GetEventIssuerReductionCodes(uuid: string): Promise<ReductionCode[]>;

    GetEventLocationSections(uuid: string): Promise<Section[]>;

    CreateMembershipCard(data: MembershipCardCreationData): Promise<MembershipCard>;

    GetEventSellingTickets(uuid: string): Promise<SellingTickets>;

    SellTicket(uuid: string): Promise<UserTicket>;

    UnsellTicket(uuid: string): Promise<UserTicket>;

    AddTicketToPreorder(uuid: string): Promise<Preorder>;

    AddTicketClusterToPreorder(uuid: string): Promise<Preorder>;

    RemoveTicketToPreorder(uuid: string): Promise<boolean>;

    AssociateMembershipCard(ticketOrderUuid: string, membershipCardUuid: string): Promise<Ticketorder>;

    DisassociateMembershipCard(ticketOrderUuid: string): Promise<Ticketorder>;

    FinalizePreorder(): Promise<Preorder>;

    GetOrder(id: string): Promise<Preorder>;

    UploadProfileImage(formData: FormData): Promise<UserInfo>;

    GetMediaUrl(id: string): Promise<string>;

    GetBase64Media(id: string): Promise<string>

    GetRawMedia(id: string): Promise<void>

    UploadKYCDocs(formData: FormData, additional: boolean): Promise<PayoutProfile>,

    UpdatePayoutProfile(payoutProfile: PayoutProfile): Promise<PayoutProfile>,

    GetUserTransactions(): Promise<BalanceLog[]>

    GetValidations(): Promise<MembershipCardValidation[]>

    PostValidation(eventIssuerUuid:string): Promise<void>

    GetSingleValidation(id:string): Promise<MembershipCardValidation>

    RequirePayout(): Promise<void>,

    ConfirmPartialOrder(uuid: string): Promise<Preorder>,

    CancelPartialOrder(uuid: string): Promise<Preorder>

    DeleteUser(confirmationPassword: string): Promise<void>
}

// @ts-ignore
const ApiContext = createContext<ApiContextIFace>();


const useApi = (): ApiContextIFace => {
    return useContext(ApiContext)
}

export default ApiContext;
export { useApi }
